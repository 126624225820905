export const pageLinks = {
  intro: "/introduction",
  logo: "/logo",
  tagline: "/tagline",
  typography: "/typography",
  photography: "/photography",
  colours: "/colours",
  endframe: "/tvc-endframe",
  "hey-toyota": "/hey-toyota",
  campaigns: "/product-campaigns",
  partnerships: "/partnerships",
  storeplaybook: "/store-playbook"
};