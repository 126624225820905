import * as React from "react";
import { Link } from "gatsby";

import logo from "../../images/0. Nav/Toyota_Logo.svg";

import { pageLinks } from "../../data/pageLinks";

const MainNavigation = (props) => {
  return (
    <div id="main-navigation">
      <div className="ui text vertical menu">
        <div className="header item">
          <Link to={"/"}>
            <img
              src={logo}
              style={{ height: "93px" }}
              alt="Toyota VIS Homepage"
              className="ui image"
            />
          </Link>
          <div className="sub-header">New Zealand</div>
        </div>
        {props.page !== "playbook" ? (
          <>
            <div className="item">
              <Link
                partiallyActive={true}
                to={pageLinks.intro}
                activeClassName="active"
                id="introduction">
                Introduction
                <i>
                  <svg width="15px" height="7px" viewBox="0 0 15 7">
                    <g
                      className="Symbols"
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd">
                      <g
                        className="Nav/down-arrow"
                        transform="translate(0.000000, 1.000000)"
                        stroke="#000000">
                        <polyline points="0 0 7.49975848 5 15 0"></polyline>
                      </g>
                    </g>
                  </svg>
                </i>
              </Link>
              <div className="menu">
                <div className="item">
                  <Link
                    to={`${pageLinks.intro}#tone-of-voice`}
                    onClick={(e) => props.subMenuItemClick(e)}
                    className="sub-category"
                    activeClassName="active"
                    id="introduction-tone-of-voice"
                    data-subsection="introduction">
                    Tone of Voice
                  </Link>
                </div>

                <div className="item">
                  <Link
                    to={`${pageLinks.intro}#brand-elements`}
                    onClick={(e) => props.subMenuItemClick(e)}
                    className="sub-category"
                    activeClassName="active"
                    id="introduction-brand-elements"
                    data-subsection="introduction">
                    Brand Elements
                  </Link>
                </div>
              </div>
            </div>
            <div className="item">
              <Link
                partiallyActive={true}
                to={pageLinks.logo}
                activeClassName="active"
                id="logo"
                data-subsection="basic-rules"
                data-index="2">
                Logo
                <i>
                  <svg width="15px" height="7px" viewBox="0 0 15 7">
                    <g
                      className="Symbols"
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd">
                      <g
                        className="Nav/down-arrow"
                        transform="translate(0.000000, 1.000000)"
                        stroke="#000000">
                        <polyline points="0 0 7.49975848 5 15 0"></polyline>
                      </g>
                    </g>
                  </svg>
                </i>
              </Link>
              <div className="menu">
                <div className="item">
                  <Link
                    to={`${pageLinks.logo}#overview`}
                    onClick={(e) => props.subMenuItemClick(e)}
                    className="sub-category"
                    data-subsection="logo"
                    data-index="1"
                    id="logo-overview">
                    Overview
                  </Link>
                </div>
                <div className="item">
                  <Link
                    to={`${pageLinks.logo}#primary-logos`}
                    onClick={(e) => props.subMenuItemClick(e)}
                    className="sub-category"
                    data-subsection="logo"
                    data-index="2"
                    id="logo-primary-logos">
                    Primary Logos
                  </Link>
                </div>
                <div className="item">
                  <Link
                    to={`${pageLinks.logo}#toyota`}
                    onClick={(e) => props.subMenuItemClick(e)}
                    className="sub-category"
                    data-subsection="logo"
                    data-index="3"
                    id="logo-toyota">
                    Toyota
                  </Link>
                </div>
                <div className="item">
                  <Link
                    to={`${pageLinks.logo}#lets-go-places`}
                    onClick={(e) => props.subMenuItemClick(e)}
                    className="sub-category"
                    data-subsection="logo"
                    data-index="4"
                    id="logo-lets-go-places">
                    Let’s Go Places
                  </Link>
                </div>
                <div className="item">
                  <Link
                    to={`${pageLinks.logo}#vehicles`}
                    onClick={(e) => props.subMenuItemClick(e)}
                    className="sub-category"
                    data-subsection="logo"
                    data-index="5"
                    id="logo-vehicles">
                    Vehicles
                  </Link>
                </div>

                <div className="item">
                  <Link
                    to={`${pageLinks.logo}#sub-brand`}
                    onClick={(e) => props.subMenuItemClick(e)}
                    className="sub-category"
                    data-subsection="logo"
                    data-index="6"
                    id="logo-sub-brand">
                    Sub-brand Logos
                  </Link>
                </div>
                <div className="item">
                  <Link
                    to={`${pageLinks.logo}#corporate-logo`}
                    onClick={(e) => props.subMenuItemClick(e)}
                    className="sub-category"
                    data-subsection="logo"
                    data-index="7"
                    id="logo-corporate-logo">
                    Corporate Logo
                  </Link>
                </div>
                <div className="item">
                  <Link
                    to={`${pageLinks.logo}#configurations`}
                    onClick={(e) => props.subMenuItemClick(e)}
                    className="sub-category"
                    data-subsection="logo"
                    data-index="8"
                    id="logo-configurations">
                    Configurations
                  </Link>
                </div>
                <div className="item">
                  <Link
                    to={`${pageLinks.logo}#colour-variations`}
                    onClick={(e) => props.subMenuItemClick(e)}
                    className="sub-category"
                    data-subsection="logo"
                    data-index="9"
                    id="logo-colour-variations">
                    Colour Variations
                  </Link>
                </div>
                <div className="item">
                  <Link
                    to={`${pageLinks.logo}#hey-toyota-logo`}
                    onClick={(e) => props.subMenuItemClick(e)}
                    className="sub-category"
                    data-subsection="logo"
                    data-index="10"
                    id="logo-hey-toyota-logo">
                    Hey Toyota
                  </Link>
                </div>
                <div className="item">
                  <Link
                    to={`${pageLinks.logo}#incorrect-use`}
                    onClick={(e) => props.subMenuItemClick(e)}
                    className="sub-category"
                    data-subsection="logo"
                    data-index="11"
                    id="logo-incorrect-use">
                    Incorrect Use
                  </Link>
                </div>
              </div>
            </div>
            <div className="item">
              <Link
                partiallyActive={true}
                to={pageLinks.typography}
                id="typography"
                activeClassName="active"
                data-subsection="basic-rules"
                data-index="5">
                Typography
                <i>
                  <svg width="15px" height="7px" viewBox="0 0 15 7">
                    <g
                      className="Symbols"
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd">
                      <g
                        className="Nav/down-arrow"
                        transform="translate(0.000000, 1.000000)"
                        stroke="#000000">
                        <polyline points="0 0 7.49975848 5 15 0"></polyline>
                      </g>
                    </g>
                  </svg>
                </i>
              </Link>
              <div className="menu">
                <div className="item">
                  <Link
                    to={`${pageLinks.typography}#overview`}
                    onClick={(e) => props.subMenuItemClick(e)}
                    className="sub-category"
                    data-subsection="Typography"
                    data-index="1"
                    id="typography-overview">
                    Overview
                  </Link>
                </div>
                <div className="item">
                  <Link
                    to={`${pageLinks.typography}#toyota-type`}
                    onClick={(e) => props.subMenuItemClick(e)}
                    className="sub-category"
                    data-subsection="Typography"
                    data-index="2"
                    id="typography-toyota-type">
                    Toyota Type
                  </Link>
                </div>
                <div className="item">
                  <Link
                    to={`${pageLinks.typography}#headlines`}
                    onClick={(e) => props.subMenuItemClick(e)}
                    className="sub-category"
                    data-subsection="Typography"
                    data-index="3"
                    id="typography-typography-settings">
                    Headlines
                  </Link>
                </div>
                <div className="item">
                  <Link
                    to={`${pageLinks.typography}#typography-subheads`}
                    onClick={(e) => props.subMenuItemClick(e)}
                    className="sub-category"
                    data-subsection="Typography"
                    data-index="4"
                    id="typography-subheads">
                    Subheads
                  </Link>
                </div>
                <div className="item">
                  <Link
                    to={`${pageLinks.typography}#body-copy`}
                    onClick={(e) => props.subMenuItemClick(e)}
                    className="sub-category"
                    data-subsection="Typography"
                    data-index="5"
                    id="typography-body-copy">
                    Body Copy
                  </Link>
                </div>
                <div className="item">
                  <Link
                    to={`${pageLinks.typography}#legal-text`}
                    onClick={(e) => props.subMenuItemClick(e)}
                    className="sub-category"
                    data-subsection="Typography"
                    data-index="6"
                    id="typography-legal-text">
                    Legal Text
                  </Link>
                </div>
              </div>
            </div>
            <div className="item">
              <Link
                partiallyActive={true}
                to={pageLinks.colours}
                id="colours"
                activeClassName="active"
                data-subsection="basic-rules"
                data-index="6">
                Colours
                <i>
                  <svg width="15px" height="7px" viewBox="0 0 15 7">
                    <g
                      className="Symbols"
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd">
                      <g
                        className="Nav/down-arrow"
                        transform="translate(0.000000, 1.000000)"
                        stroke="#000000">
                        <polyline points="0 0 7.49975848 5 15 0"></polyline>
                      </g>
                    </g>
                  </svg>
                </i>
              </Link>
              <div className="menu">
                <div className="item">
                  <Link
                    to={`${pageLinks.colours}#overview`}
                    onClick={(e) => props.subMenuItemClick(e)}
                    className="sub-category"
                    data-subsection="colours"
                    data-index="1"
                    id="colours-overview">
                    Overview
                  </Link>
                </div>
                <div className="item">
                  <Link
                    to={`${pageLinks.colours}#brand-colours`}
                    onClick={(e) => props.subMenuItemClick(e)}
                    className="sub-category"
                    data-subsection="colours"
                    data-index="2"
                    id="colours-brand-colours">
                    Brand Colours
                  </Link>
                </div>
                <div className="item">
                  <Link
                    to={`${pageLinks.colours}#colour-mode`}
                    onClick={(e) => props.subMenuItemClick(e)}
                    className="sub-category"
                    data-subsection="colours"
                    data-index="3"
                    id="colours-colour-mode">
                    Colour Mode
                  </Link>
                </div>
              </div>
            </div>
            <div className="item">
              <Link
                partiallyActive={true}
                to={pageLinks.tagline}
                activeClassName="active"
                id="tagline"
                data-subsection="basic-rules"
                data-index="3">
                Tagline
                <i>
                  <svg width="15px" height="7px" viewBox="0 0 15 7">
                    <g
                      className="Symbols"
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd">
                      <g
                        className="Nav/down-arrow"
                        transform="translate(0.000000, 1.000000)"
                        stroke="#000000">
                        <polyline points="0 0 7.49975848 5 15 0"></polyline>
                      </g>
                    </g>
                  </svg>
                </i>
              </Link>
              <div className="menu">
                <div className="item">
                  <Link
                    to={`${pageLinks.tagline}#overview`}
                    onClick={(e) => props.subMenuItemClick(e)}
                    className="sub-category"
                    data-subsection="Tagline"
                    data-index="1"
                    id="tagline-overview">
                    Overview
                  </Link>
                </div>
                <div className="item">
                  <Link
                    to={`${pageLinks.tagline}#used-as-headline`}
                    onClick={(e) => props.subMenuItemClick(e)}
                    className="sub-category"
                    data-subsection="Tagline"
                    data-index="2"
                    id="tagline-used-as-headline">
                    Used as Headline
                  </Link>
                </div>
                <div className="item">
                  <Link
                    to={`${pageLinks.tagline}#used-as-logo`}
                    onClick={(e) => props.subMenuItemClick(e)}
                    className="sub-category"
                    data-subsection="Tagline"
                    data-index="3"
                    id="tagline-used-as-logo">
                    Used as a Logo
                  </Link>
                </div>
                <div className="item">
                  <Link
                    to={`${pageLinks.tagline}#used-in-body-text`}
                    onClick={(e) => props.subMenuItemClick(e)}
                    className="sub-category"
                    data-subsection="Tagline"
                    data-index="4"
                    id="tagline-used-in-body-text">
                    Used in Body Text
                  </Link>
                </div>
              </div>
            </div>
            <div className="item">
              <Link
                partiallyActive={true}
                to={pageLinks.photography}
                activeClassName="active"
                id="photography"
                data-subsection="basic-rules"
                data-index="4">
                Photography
                <i>
                  <svg width="15px" height="7px" viewBox="0 0 15 7">
                    <g
                      className="Symbols"
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd">
                      <g
                        className="Nav/down-arrow"
                        transform="translate(0.000000, 1.000000)"
                        stroke="#000000">
                        <polyline points="0 0 7.49975848 5 15 0"></polyline>
                      </g>
                    </g>
                  </svg>
                </i>
              </Link>
              <div className="menu">
                <div className="item">
                  <Link
                    to={`${pageLinks.photography}#overview`}
                    onClick={(e) => props.subMenuItemClick(e)}
                    className="sub-category"
                    data-subsection="photography"
                    data-index="1"
                    id="photography-overview">
                    Overview
                  </Link>
                </div>
                <div className="item">
                  <Link
                    to={`${pageLinks.photography}#people`}
                    onClick={(e) => props.subMenuItemClick(e)}
                    className=" sub-category"
                    data-subsection="photography"
                    data-index="2"
                    id="photography-people">
                    People
                  </Link>
                </div>
                <div className="item">
                  <Link
                    to={`${pageLinks.photography}#place`}
                    onClick={(e) => props.subMenuItemClick(e)}
                    className=" sub-category"
                    data-subsection="photography"
                    data-index="3"
                    id="photography-place">
                    Place
                  </Link>
                </div>
                <div className="item">
                  <Link
                    to={`${pageLinks.photography}#product`}
                    onClick={(e) => {
                      props.subMenuItemClick(e);
                    }}
                    className=" sub-category"
                    data-subsection="photography"
                    data-index="4"
                    id="photography-product">
                    Product
                  </Link>
                </div>
                <div className="item">
                  <Link
                    to={`${pageLinks.photography}#lifestyle`}
                    onClick={(e) => {
                      props.subMenuItemClick(e);
                    }}
                    className=" sub-category"
                    data-subsection="photography"
                    data-index="5"
                    id="photography-lifestyle">
                    Lifestyle
                  </Link>
                </div>
              </div>
            </div>
            <div className="item" style={{ display: "none" }}>
              <Link
                partiallyActive={true}
                to={pageLinks.endframe}
                activeClassName="active"
                data-subsection="basic-rules"
                data-index="7">
                TVC Endframe
                <i>
                  <svg width="15px" height="7px" viewBox="0 0 15 7">
                    <g
                      className="Symbols"
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd">
                      <g
                        className="Nav/down-arrow"
                        transform="translate(0.000000, 1.000000)"
                        stroke="#000000">
                        <polyline points="0 0 7.49975848 5 15 0"></polyline>
                      </g>
                    </g>
                  </svg>
                </i>
              </Link>
              <div className="menu">
                <div className="item">
                  <Link
                    to={`${pageLinks.endframe}/#overview`}
                    onClick={(e) => props.subMenuItemClick(e)}
                    className="sub-category"
                    data-subsection="tvc-endframe"
                    data-index="1"
                    id="tvc-endframe-overview">
                    Overview
                  </Link>
                </div>
                <div className="item">
                  <Link
                    to={`${pageLinks.endframe}#endframe`}
                    onClick={(e) => props.subMenuItemClick(e)}
                    className="sub-category"
                    data-subsection="tvc-endframe"
                    data-index="2"
                    id="tvc-endframe-endframe">
                    Endframe
                  </Link>
                </div>
              </div>
            </div>
            <div className="item">
              <Link
                partiallyActive={true}
                to={pageLinks["hey-toyota"]}
                id="hey-toyota"
                activeClassName="active"
                data-subsection="basic-rules"
                data-index="7">
                Hey Toyota
                <i>
                  <svg width="15px" height="7px" viewBox="0 0 15 7">
                    <g
                      className="Symbols"
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd">
                      <g
                        className="Nav/down-arrow"
                        transform="translate(0.000000, 1.000000)"
                        stroke="#000000">
                        <polyline points="0 0 7.49975848 5 15 0"></polyline>
                      </g>
                    </g>
                  </svg>
                </i>
              </Link>
              <div className="menu">
                <div className="item">
                  <Link
                    to={`${pageLinks["hey-toyota"]}#overview`}
                    onClick={(e) => props.subMenuItemClick(e)}
                    className="sub-category"
                    data-subsection="hey-toyota"
                    data-index="1"
                    id="hey-toyota-overview">
                    Overview
                  </Link>
                </div>
                <div className="item">
                  <Link
                    to={`${pageLinks["hey-toyota"]}#approach`}
                    onClick={(e) => props.subMenuItemClick(e)}
                    className="sub-category"
                    data-subsection="hey-toyota"
                    data-index="2"
                    id="hey-toyota-approach">
                    The Approach
                  </Link>
                </div>
                <div className="item">
                  <Link
                    to={`${pageLinks["hey-toyota"]}#construct`}
                    onClick={(e) => props.subMenuItemClick(e)}
                    className="sub-category"
                    data-subsection="hey-toyota"
                    data-index="3"
                    id="hey-toyota-construct">
                    The Construct
                  </Link>
                </div>
                <div className="item">
                  <Link
                    to={`${pageLinks["hey-toyota"]}#hey-toyota-photography`}
                    onClick={(e) => props.subMenuItemClick(e)}
                    className="sub-category"
                    data-subsection="hey-toyota"
                    data-index="4"
                    id="hey-toyota-hey-toyota-photography">
                    Hey Toyota Photography
                  </Link>
                </div>
                <div className="item">
                  <Link
                    to={`${pageLinks["hey-toyota"]}#hey-toyota-copy`}
                    onClick={(e) => props.subMenuItemClick(e)}
                    className="sub-category"
                    data-subsection="hey-toyota"
                    data-index="5"
                    id="hey-toyota-hey-toyota-copy">
                    Hey Toyota Copy
                  </Link>
                </div>
                <div className="item">
                  <Link
                    to={`${pageLinks["hey-toyota"]}#question-formats`}
                    onClick={(e) => props.subMenuItemClick(e)}
                    className="sub-category"
                    data-subsection="hey-toyota"
                    data-index="6"
                    id="hey-toyota-question-formats">
                    Question Formats
                  </Link>
                </div>
                <div className="item">
                  <Link
                    to={`${pageLinks["hey-toyota"]}#call-out-graphics`}
                    onClick={(e) => props.subMenuItemClick(e)}
                    className="sub-category"
                    data-subsection="hey-toyota"
                    data-index="7"
                    id="hey-toyota-call-out-graphics">
                    CTA/Call Out Graphics
                  </Link>
                </div>
                <div className="item">
                  <Link
                    to={`${pageLinks["hey-toyota"]}#toyota-drive-away-price-lockup`}
                    onClick={(e) => props.subMenuItemClick(e)}
                    className="sub-category"
                    data-subsection="hey-toyota"
                    data-index="8"
                    id="hey-toyota-toyota-drive-away-price-lockup">
                    Toyota Driveaway Price lockup
                  </Link>
                </div>
              </div>
            </div>
            <div className="item">
              <Link
                partiallyActive={true}
                to={pageLinks.campaigns}
                id="product-campaigns"
                activeClassName="active"
                data-subsection="basic-rules"
                data-index="7">
                Product Campaigns
                <i>
                  <svg width="15px" height="7px" viewBox="0 0 15 7">
                    <g
                      className="Symbols"
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd">
                      <g
                        className="Nav/down-arrow"
                        transform="translate(0.000000, 1.000000)"
                        stroke="#000000">
                        <polyline points="0 0 7.49975848 5 15 0"></polyline>
                      </g>
                    </g>
                  </svg>
                </i>
              </Link>
              <div className="menu">
                <div className="item">
                  <Link
                    to={`${pageLinks.campaigns}#overview`}
                    onClick={(e) => props.subMenuItemClick(e)}
                    className="sub-category"
                    data-subsection="product-campaigns"
                    data-index="1"
                    id="product-campaigns-overview">
                    Overview
                  </Link>
                </div>
                <div className="item">
                  <Link
                    to={`${pageLinks.campaigns}#display-fonts`}
                    onClick={(e) => props.subMenuItemClick(e)}
                    className="sub-category"
                    data-subsection="product-campaigns"
                    data-index="2"
                    id="product-campaigns-display-fonts">
                    Display Fonts
                  </Link>
                </div>
                <div className="item">
                  <Link
                    to={`${pageLinks.campaigns}#campaign-colours`}
                    onClick={(e) => props.subMenuItemClick(e)}
                    className="sub-category"
                    data-subsection="product-campaigns"
                    data-index="3"
                    id="product-campaigns-campaign-colours">
                    Campaign Colours
                  </Link>
                </div>
                <div className="item">
                  <Link
                    to={`${pageLinks.campaigns}#layout-examples`}
                    onClick={(e) => props.subMenuItemClick(e)}
                    className="sub-category"
                    data-subsection="product-campaigns"
                    data-index="4"
                    id="product-campaigns-layout-examples">
                    Layout Examples
                  </Link>
                </div>
              </div>
            </div>
            <div className="item">
              <Link
                partiallyActive={true}
                to={pageLinks.partnerships}
                id="partnerships"
                activeClassName="active"
                data-subsection="basic-rules"
                data-index="7">
                Partnerships
                <i>
                  <svg width="15px" height="7px" viewBox="0 0 15 7">
                    <g
                      className="Symbols"
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd">
                      <g
                        className="Nav/down-arrow"
                        transform="translate(0.000000, 1.000000)"
                        stroke="#000000">
                        <polyline points="0 0 7.49975848 5 15 0"></polyline>
                      </g>
                    </g>
                  </svg>
                </i>
              </Link>
              <div className="menu">
                <div className="item">
                  <Link
                    to={`${pageLinks.partnerships}#overview`}
                    onClick={(e) => props.subMenuItemClick(e)}
                    className="sub-category"
                    data-subsection="partnerships"
                    data-index="1"
                    id="partnerships-overview">
                    Overview
                  </Link>
                </div>
                <div className="item">
                  <Link
                    to={`${pageLinks.partnerships}#logo-selection`}
                    onClick={(e) => props.subMenuItemClick(e)}
                    className="sub-category"
                    data-subsection="partnerships"
                    data-index="2"
                    id="partnerships-logo-selection">
                    Logo Selection
                  </Link>
                </div>
                <div className="item">
                  <Link
                    to={`${pageLinks.partnerships}#title-sponsorships`}
                    onClick={(e) => props.subMenuItemClick(e)}
                    className="sub-category"
                    data-subsection="partnerships"
                    data-index="3"
                    id="partnerships-title-sponsorships">
                    Title Sponsorships
                  </Link>
                </div>
                <div className="item">
                  <Link
                    to={`${pageLinks.partnerships}#co-branding-lockups`}
                    onClick={(e) => props.subMenuItemClick(e)}
                    className="sub-category"
                    data-subsection="partnerships"
                    data-index="4"
                    id="partnerships-co-branding-lockups">
                    Co-branding lockups
                  </Link>
                </div>
                <div className="item">
                  <Link
                    to={`${pageLinks.partnerships}#configurations`}
                    onClick={(e) => props.subMenuItemClick(e)}
                    className="sub-category"
                    data-subsection="partnerships"
                    data-index="5"
                    id="partnerships-configurations">
                    Configurations
                  </Link>
                </div>
                <div className="item">
                  <Link
                    to={`${pageLinks.partnerships}#layout-examples`}
                    onClick={(e) => props.subMenuItemClick(e)}
                    className="sub-category"
                    data-subsection="partnerships"
                    data-index="6"
                    id="partnerships-layout-examples">
                    Layout Examples
                  </Link>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="item">
            <Link
              partiallyActive={true}
              to={pageLinks.storeplaybook}
              activeClassName="active"
              id="store-playbook"
              data-subsection="basic-rules"
              data-index="2">
              Store Playbook
              <i>
                <svg width="15px" height="7px" viewBox="0 0 15 7">
                  <g
                    className="Symbols"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd">
                    <g
                      className="Nav/down-arrow"
                      transform="translate(0.000000, 1.000000)"
                      stroke="#000000">
                      <polyline points="0 0 7.49975848 5 15 0"></polyline>
                    </g>
                  </g>
                </svg>
              </i>
            </Link>
            <div className="menu">
              <div className="item">
                <Link
                  to={`${pageLinks.storeplaybook}#introduction`}
                  onClick={(e) => props.subMenuItemClick(e)}
                  className="sub-category"
                  data-index="1"
                  id="store-playbook-introduction"
                  data-subsection="store-playbook">
                  Introduction
                </Link>
              </div>
              <div className="item">
                <Link
                  to={`${pageLinks.storeplaybook}#brand-partnership`}
                  onClick={(e) => props.subMenuItemClick(e)}
                  className="sub-category"
                  data-index="2"
                  id="store-playbook-brand-partnership"
                  data-subsection="store-playbook">
                  Brand Partnerships
                </Link>
              </div>
              <div className="item">
                <Link
                  to={`${pageLinks.storeplaybook}#local-sponsorships-and-partnerships`}
                  onClick={(e) => props.subMenuItemClick(e)}
                  className="sub-category"
                  data-index="3"
                  id="store-playbook-local-sponsorships-and-partnerships"
                  data-subsection="store-playbook">
                  Local Sponsorships and Partnerships
                </Link>
              </div>
              <div className="item">
                <Link
                  to={`${pageLinks.storeplaybook}#toyota-store-logos`}
                  onClick={(e) => props.subMenuItemClick(e)}
                  className="sub-category"
                  data-index="5"
                  id="store-playbook-toyota-store-logos"
                  data-subsection="store-playbook">
                  Toyota Store Logos
                </Link>
              </div>
              <div className="item">
                <Link
                  to={`${pageLinks.storeplaybook}#lgp-store-logos`}
                  onClick={(e) => props.subMenuItemClick(e)}
                  className="sub-category"
                  data-index="6"
                  id="store-playbook-lgp-store-logos"
                  data-subsection="store-playbook">
                  <em>Let’s Go Places</em> Store Logos
                </Link>
              </div>
              <div className="item">
                <Link
                  to={`${pageLinks.storeplaybook}#store-email-signatures`}
                  onClick={(e) => props.subMenuItemClick(e)}
                  className="sub-category"
                  data-index="7"
                  id="store-playbook-store-email-signatures"
                  data-subsection="store-playbook">
                  Store Email Signatures
                </Link>
              </div>
              <div className="item">
                <Link
                  to={`${pageLinks.storeplaybook}#social-101`}
                  onClick={(e) => props.subMenuItemClick(e)}
                  className="sub-category"
                  data-index="8"
                  id="store-playbook-social-101"
                  data-subsection="store-playbook">
                  Social 101
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default MainNavigation;